<template>
  <div>
    <div class="title">{{ $t("baseInfo") }}</div>
    <el-form ref="form" :model="form" :label-width="language === 'zh-cn' ? '200px' : '230px'">
      <el-form-item :label="$t('investorType')" style="width: 100%">
        <span>
          {{
          form.investorType === symbol.ORGANIZATION.valueNew
          ? $t("investorTypeRadio.institutionalInvestor")
          : $t("investorTypeRadio.individualInvestor")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('investorName')" style="width: 100%">
        <span>{{ form.investorName }}</span>
      </el-form-item>
      <el-form-item :label="$t('investorEMail')" style="width: 100%">
        <span>{{ form.investorEmail }}</span>
      </el-form-item>
      <el-form-item :label="$t('investorMobilePhoneNumber')" style="width: 100%">
        <span>{{ form.investorMobileNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('idCardOrCompanyCode')" style="width: 100%">
        <span>{{ form.cardId }}</span>
      </el-form-item>
      <el-form-item :label="$t('supportingDocumentationFile')" style="width: 100%">
        <template v-if="form.supportingDocument">
          <template v-for="(item, index) in JSON.parse(form.supportingDocument)">
            <div :key="index">
              <el-link :underline="false" type="primary" @click="toReviewImg(item)" :key="index">
                {{
                item &&
                item.split("/")[item.split("/").length - 1]
                }}
              </el-link>
            </div>
          </template>
        </template>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("bankInformation") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="200px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="190px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" label-width="200px">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
      <el-form-item :label="$t('swiftCode')" label-width="200px">
        <span>{{ form.swiftCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankNumber')">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankAccount')" label-width="200px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("investmentInformation") }}</div>
    <el-form
      ref="form"
      :model="form"
      style="width: 1100px"
      :label-width="language === 'zh-cn' ? '200px' : '300px'"
    >
      <el-form-item :label="$t('targetTotalInvestmentSize')" style="width: 100%">
        <span>{{ (Number(form.totalInvestmentSize || 0) / $enums.UNIT_MILLION).toFixed(2) }}&nbsp;&nbsp;{{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('riskTolerance')" style="width: 100%">
        <span>
          {{
          form.riskTolerance === risk.KEEP.valueNew
          ? $t("riskToleranceRadio.conservative")
          : form.riskTolerance === risk.PRUDENT.valueNew
          ? $t("riskToleranceRadio.cautious")
          : form.riskTolerance === risk.STEADY.valueNew
          ? $t("riskToleranceRadio.steady")
          : form.riskTolerance === risk.ACTIVE.valueNew
          ? $t("riskToleranceRadio.positive")
          : $t("riskToleranceRadio.radical")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('experienceInSupplyChainInvestment')" style="width: 100%">
        <span>
          {{
          form.invertmentYears === experience.NONE.valueNew
          ? $t("experienceInSupplyChainInvestmentRadio.none")
          : form.invertmentYears === experience.ONE_TO_THREE.valueNew
          ? $t("experienceInSupplyChainInvestmentRadio.otYears")
          : form.invertmentYears === experience.THREE_TO_FIVE.valueNew
          ? $t("experienceInSupplyChainInvestmentRadio.tfYears")
          : form.invertmentYears === experience.FIVE_TO_TEN.valueNew
          ? $t("experienceInSupplyChainInvestmentRadio.ftYears")
          : $t("experienceInSupplyChainInvestmentRadio.aboveYears")
          }}
        </span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button
        v-if="form.status && form.status.toString() === authAll.PROCESSING.valueNew"
        :disabled="form.status && form.status.toString() === authAll.UNKNOWN.valueNew || isSubmit"
        class="btn-black"
        v-on:click="approve(true)"
      >{{ $t("approve") }}</el-button>
      <el-button
        v-if="form.status && form.status.toString() === authAll.PROCESSING.valueNew"
        :disabled="form.status && form.status.toString() === authAll.UNKNOWN.valueNew || isSubmit"
        class="btn-gray"
        v-on:click="approve(false)"
      >{{ $t("reject") }}</el-button>
      <el-button
        v-if="!form.status || form.status.toString() !== authAll.PROCESSING.valueNew"
        class="btn-black"
        v-on:click="close"
      >{{ $t("btnI18n.Close") }}</el-button>
    </div>
  </div>
</template>

<script>
import {getObjByKeyValue} from "@/utils";
export default {
  name: "invest-detail",
  data() {
    return {
      symbol: this.$enums.INVESTORTYPE,
      risk: this.$enums.RISKTOLERANCE,
      experience: this.$enums.EXPERIENCEINVESTMENT,
      auth: this.$route.query.auth,
      authAll: this.$enums.AUTH,
      isSubmit: false,
      form: {},
      address: '',
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  created() {
      this.getAreaList();
    this.initData();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    toReviewImg(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    initData() {
      let _this = this;
      this.$axios.get("/manage-invest/detail", { params: { userId: this.$route.query.id } }).then(result => {
        if (!result.code && result.data) {
          _this.form = result.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    async approve(status) {
      this.isSubmit = true;
      let _this = this;
      let approve = status ? this.authAll.CONFIRM.valueNew : this.authAll.REJECT.valueNew;
      this.$axios.post("/manage-invest/review", { status: approve, userId: this.$route.query.id }).then(result => {
        if (!result.code) {
          _this.isSubmit = false;
          _this.close();
        }
      }).catch(error => {
        this.isSubmit = false;
        console.log(error);
      });
    },
    close() {
      this.$router.replace({ path: "/investor-management" });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0;
}
</style>
